

import { useEffect, useState } from 'react';
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, useLocation } from "react-router-dom";

import axios from "axios";

const Item = ({ title, to, icon, selected, setSelected }) => {
    return (
      <MenuItem
        active={selected === title}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <h6>{title}</h6>
        <Link to={to} />
      </MenuItem>
    );
};


const SidebarObject = () => {

    // Set current sidebar nav based on url path
    var currentPath = useLocation()['pathname']
    if( currentPath.charAt(0) === '/')
        currentPath = currentPath.substring(1);
    if( currentPath === "")
        currentPath = "Home";
    currentPath = currentPath.charAt(0).toUpperCase() + currentPath.slice(1);
    const [selected, setSelected] = useState(currentPath);
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let apiHost = process.env.REACT_APP_API_HOST;
    if (apiHost == null) {
        apiHost = 'http://localhost:8000';
    }

    if (error || loading){
    }

    const FetchAdminNavigation = async () => {
        setLoading(true);
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
        await axios
        .get(apiHost + '/user_details', {
            withCredentials: true,
        })
        .then(
            (res) => {
                setUserData(res.data);
                setLoading(false);
            }
        )
        .catch(
            (res) => {
                setError(true);
                console.log('Error: ', res.response.statusText);
            }
        )
    };

    // eslint-disable-next-line
    useEffect(() => { FetchAdminNavigation(); }, []);

    return (
        <ProSidebar>
            <Menu
                menuItemStyles={{
                button: {
                    // the active class will be added automatically by react router
                    // so we can use it to style the active menu item
                    [`&.active`]: {
                    backgroundColor: '#13395e',
                    color: '#b6c8d9',
                    },
                },
                }}
            >
                <Item
                    title="Home"
                    to="/"
                    /*icon={<HomeOutlinedIcon />}*/
                    selected={selected}
                    setSelected={setSelected}
                />
                <Item
                    title="Calculate"
                    to="/calculate"
                    /*icon={<HomeOutlinedIcon />}*/
                    selected={selected}
                    setSelected={setSelected}
                />
                <Item
                    title="Heatmap"
                    to="/heatmap"
                    /*icon={<HomeOutlinedIcon />}*/
                    selected={selected}
                    setSelected={setSelected}
                />
                <Item
                    title="Answers"
                    to="/answers"
                    /*icon={<HomeOutlinedIcon />}*/
                    selected={selected}
                    setSelected={setSelected}
                />
                {userData.is_staff === true
                ? <div>
                    <Item
                        title="Edit Questions"
                        to="/edit_questions"
                        /*icon={<HomeOutlinedIcon />}*/
                        selected={selected}
                        setSelected={setSelected}
                    />
                </div>
                : <></>
                }
            </Menu>
        </ProSidebar>
    );
}

export default SidebarObject;
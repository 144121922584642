
import { Box } from "@mui/material";

import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { DataGrid } from '@mui/x-data-grid';

import axios from "axios";

import Header from "../../components/Header";


const colorScale = (value) => {
    return clsx('cell-color', {
        darkgreen: (value <= 100 && value >= 90),
        green: (value < 90 && value >= 80),
        lightgreen: (value < 80 && value >= 70),
        darkyellowlightgreen: (value < 70 && value >= 60),
        darkyellow: (value < 60 && value >= 50),
        yellow: (value < 50 && value >= 40),
        lightorange: (value < 40 && value >= 30),
        lightred: (value < 30 && value >= 20),
        red: (value < 20 && value >= 10),
        darkred: (value < 10 && value > 0),
        grey: (value === 0),
    });
};

const Heatmap = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    let apiHost = process.env.REACT_APP_API_HOST;
    if (apiHost == null) {
        apiHost = 'http://localhost:8000';
    }

    if (error || loading){

    }

    const fetchHeatmap = async () => {
        setLoading(true);
        setData([]);
        axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
        await axios
        .get(apiHost + '/heatmap', {
            withCredentials: true,
        })
        .then(
            (res) => {
                setData(res.data.heatmap);
                setLoading(false);
            }
        )
        .catch(
            (res) => {
                setError(true);
                console.log('Error: ', res.response.statusText);
            }
        )
    };

    // eslint-disable-next-line
    useEffect(() => { fetchHeatmap(); }, []);

    const columns = [
        {
            field: 'basic',
            headerName: 'Basic',
            width: 150,
            headerAlign: "center",
            align: "center",
            headerClassName: "hiddenHeaderClass",
        },
        {
            field: 'advanced',
            headerName: 'Advanced',
            width: 250,
            headerAlign: "center",
            align: "center",
            headerClassName: "hiddenHeaderClass",
        },
    ];

    const columnGroupingModel = [
        {
            groupId: 'Asset Class',
            description: 'Asset Class',
            children: [{ field: 'basic' }, { field: 'advanced' }],
            headerAlign: "center",
            headerClassName: "columnHeaderGroup",
        },
    ];

    const rows = [];

    let indexCounter = 1;

    Object.keys(data).forEach((basicAssetClass) => {
        Object.keys(data[basicAssetClass]).forEach((assetClass) => {
            let rowItems = {
                id: indexCounter,
                basic: basicAssetClass,
                advanced: assetClass,
            };

            Object.keys(data[basicAssetClass][assetClass]).forEach((functionName) => {
                const columnChildren = [];

                Object.keys(data[basicAssetClass][assetClass][functionName]).forEach((subControl) => {
                    const fieldKey = functionName + subControl;

                    // Add new column if it doesn't exist
                    const existingColumn = columns.find((column) => column.field === fieldKey);

                    if (!existingColumn) {
                        columns.push({
                            field: fieldKey,
                            headerName: `${subControl}`,
                            width: 60,
                            headerAlign: "center",
                            align: "center",
                            headerClassName: 'subControlColumnHeader',
                            fontSize: "8pt",
                            rowSpanValueGetter: (value, row) =>
                                row
                                    ? `${row.basic}-${row.advanced}-${functionName}-${subControl}`
                                    : value,
                            valueFormatter: (value) => {
                                if (value == null) {
                                    return '';
                                }
                                return `${value.toLocaleString()} %`;
                            },
                        });
                    }

                    // Add field to grouping model children
                    columnChildren.push({
                        field: fieldKey,
                    });

                    // Populate row items
                    rowItems[fieldKey] = data[basicAssetClass][assetClass][functionName][subControl];
                });

                // Check if functionName already exists in columnGroupingModel
                const existingGroup = columnGroupingModel.find(
                    (group) => group.groupId === functionName
                );

                if (!existingGroup) {
                    columnGroupingModel.push({
                        groupId: functionName,
                        description: functionName,
                        children: columnChildren,
                        headerAlign: "center",
                        headerClassName: "columnHeaderGroup",
                    });
                }
                else {
                    columnChildren.forEach((child) => {
                        const isChildExisting = existingGroup.children.some(
                            (existingChild) => existingChild.field === child.field
                        );
                
                        if (!isChildExisting) {
                            existingGroup.children.push(child);
                        }
                    });
                }
            });

            rows.push(rowItems);
            indexCounter += 1;
        });
        
    });

    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center"></Box>
            <Header title="Heatmap" subtitle="Heatmap" />
            
            <Box 
                    m="10px 0 0 0" 
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            fontSize: "8pt",
                            height: "100%"
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: "none !important",
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                            outline: "none !important",
                        },
                        "& .MuiDataGrid-columnHeaderTitleContainer:focus": {
                            outline: "none !important",
                        },
                        '& .MuiDataGrid-columnHeader:focus-within': {
                            outline: "none !important",
                        },
                        '&.MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-row:hover': {
                            backgroundColor: "inherit",
                        },
                        "& .MuiDataGrid-row.Mui-hovered": {
                            backgroundColor: "inherit"
                        },
                        '.columnHeaderGroup': {
                            padding: "0 !important",
                        },
                        '.MuiDataGrid-cell.MuiDataGrid-cellEmpty': {
                            display: "none",
                        },
                        '.MuiDataGrid-filler': {
                            display: "none",
                        },
                        '.MuiDataGrid-scrollbar--vertical': {
                            display: "none",
                        },
                        '.MuiDataGrid-scrollbar--horizontal': {
                            position: "relative",
                        }
                    }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{ pinnedColumns: { left: ['basic'] } }}
                    disableRowSelectionOnClick
                    showCellVerticalBorder
                    showColumnVerticalBorder 
                    disableColumnSorting
                    disableColumnFilter
                    disableColumnResize
                    disableColumnMenu
                    hideFooterPagination={true}
                    columnGroupingModel={columnGroupingModel}
                    loading={loading}
                    unstable_rowSpanning={true}
                    density="compact"
                    rowHeight={35}
                    getCellClassName={(params) => {
                        if (params.value == null) {
                        return '';
                        }
                        return colorScale(params.value);
                    }}
                    sx={{
                        '& .darkgreen': {
                            bgcolor: "#63BE7B"
                        },
                        '& .green': {
                            bgcolor: "#8ecc77"
                        },
                        '& .lightgreen': {
                            bgcolor: "#b6d879"
                        },
                        '& .darkyellowlightgreen': {
                            bgcolor: "#b6d879"
                        },
                        '& .darkyellow': {
                            bgcolor: "#dae17a"
                        },
                        '& .yellow': {
                            bgcolor: "#FFEB84"
                        },
                        '& .lightorange': {
                            bgcolor: "#facf70"
                        },
                        '& .lightred': {
                            bgcolor: "#ffb264"
                        },
                        '& .red': {
                            bgcolor: "#fe8163"
                        },
                        '& .darkred': {
                            bgcolor: "#F8696B"
                        },
                        '& .grey': {
                            bgcolor: "#D0CFCF"
                        }
                    }}
                />
            </Box>
        </Box>
    );
};

export default Heatmap;